import "./App.css";
import Header from "./compoents/Header";
import Home from "./compoents/Home";
import AOS from "aos";
import { useEffect } from "react";
import { ErrorBoundary } from "./compoents/ErrorBoundary";
import Footer from "./compoents/Footer";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./compoents/AboutUs";
import Career from "./compoents/Career";
import ServiceDetails from "./compoents/ServiceDetails";
import { ServiceProvider } from "./compoents/ServiceContext";
import Terms from "./compoents/Terms";
import Privacy from "./compoents/Privacy";
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      
        <ErrorBoundary>
        <ServiceProvider>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="aboutus" element={<AboutUs />}></Route>
            <Route exact path="career" element={<Career />}></Route>
            <Route path="serviceDetails/:id" element={<ServiceDetails />}></Route>
            <Route path="terms" element={<Terms/>}></Route>
            <Route path="privacy-policy" element={<Privacy/>}></Route>
          </Routes>
          <Footer />
          </ServiceProvider>
        </ErrorBoundary>
     
    </>
  );
}


export default App;
