import React from "react";
import {ErrorMessage , useField } from 'formik';

export default function TextArea({rows , label , ...props}) {
const [field, meta] = useField(props);
  return (
    <>
      <textarea
        className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
        rows={rows}
        placeholder={label}
        {...field} {...props}
      />
    <ErrorMessage component='div' className="errormsg"  name={field.name}/>
    </>
  );
}
