import React, { useContext, useEffect } from "react";
import useAxiosGet from "./../hooks/useAxiosGet";
import { Link } from 'react-router-dom';
import ServiceContext from "./ServiceContext";

export default function Service() {
  const servicesApi = `https://digital-rath-api-express.vercel.app/api/1.0/getAllService`;
  let allServices = useAxiosGet(servicesApi);
  const { services, setServices } = useContext(ServiceContext);
  if (allServices.data) {
    setServices(allServices.data);
    console.log({services});
  }

  return (
    <>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up" data-aos-delay="200">
          <header className="section-header">
            <p>Our Services</p>
          </header>

          <div className="row gy-4">
            {allServices.data &&
              allServices.data.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="col-lg-6 col-md-6"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="service-box blue">
                      <i className="ri-discuss-line icon"></i>
                      <h3>{item.serviceName}</h3>
                      <p>{item.minimumContents}</p>
                      <Link to={`/serviceDetails/${item._id}`} className="read-more">
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
}
