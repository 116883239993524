import { ErrorMessage, useField } from "formik";


export default function SelectInput({ label, data, ...props }) {
  const [field, meta] = useField(props);
  let options = data.data ? data.data : [];
  return (
    <>

      <select
        initialValues="Select"
        className={`form-select shadow-none ${meta.touched && meta.error && "is-invalid"
          }`}
        {...field} {...props}
      >
        <option value="Select" selected>
          {label}
        </option>

        {options.map((data) => {

          return <option key={data._id} value={data._id}>{data.level ? data.level : data.role}</option>
        })}

      </select>
      <ErrorMessage component="div" className="errormsg" name={field.name} />
    </>
  );
}
