import React, { useEffect } from "react";
import { Link } from "react-router-dom";
export default function AboutUs() {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ol>
            <h2>About Us</h2>
          </div>
        </section>

        <section className="inner-page">
          <div className="container">
            <p>
              Digitalrath acts as a tech partner with a vision for the business.
              We help you transform your concepts into effective digital
              products by building them from scratch and providing support for
              your project for as long as you need. We've watched our clients
              grow from small start-ups to enterprise firms. We were with them
              when they joined forces with the top industry players and bought
              smaller businesses. We were there to celebrate every fundraiser,
              launch and success. We're excited to keep in the same way with
              you.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
