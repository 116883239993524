import { createContext, useState} from 'react'


const ServiceContext = createContext();

export function ServiceProvider({ children }) {
    const [services, setServices] = useState([]);
    return (
        <ServiceContext.Provider value={{services , setServices}}>{children}</ServiceContext.Provider>
    );
}

export default ServiceContext;