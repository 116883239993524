import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAxiosGet from "./../hooks/useAxiosGet";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
export default function ServiceDetails() {
  const servicesApi = `https://digital-rath-api-express.vercel.app/api/1.0/getServiceDetails/`;
  let { id } = useParams();
  const contents = useAxiosGet(servicesApi + id);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTitle()
  }, [contents]);

  const setTitle = async()=>{
    await contents;
    if(contents.data.serviceName)
    document.title = contents.data.serviceName
  }
  const override = css`
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    margin-bottom: 5em;
  `;
  return (
    <>
      <ClipLoader
        color="#012970"
        loading={contents.loading}
        css={override}
        size={130}
      />
      {contents.data && (
        <main id="main">
          <section className="breadcrumbs">
            <div className="container">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>{contents.data.serviceName}</li>
              </ol>
              <h2>{contents.data.serviceName}</h2>
            </div>
          </section>

          <section className="inner-page">
            <div className="container">
              <p
                dangerouslySetInnerHTML={{
                  __html: contents.data.serviceContents,
                }}
              ></p>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
