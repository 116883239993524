import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ServiceContext from "./ServiceContext";

export default function Footer() {
  const { services } = useContext(ServiceContext);
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-info">
                <a href="index.html" className="logo d-flex align-items-center">
                  <img src="assets/img/logo.png" alt="" />
                </a>
                <strong>We Design with ❤️</strong>
                <p>
                  You can see our passion for design and code in all of our
                  work. We love spending time learning and evolving with each
                  project we take.
                </p>
                <div className="social-links mt-3">
                  <a href="https://www.facebook.com/digitalratha" target="_blank" className="facebook">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/digitalrath/" target="_blank" className="instagram">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/digital-rath/"  target="_blank" className="linkedin">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a className="scrollto" href="#">
                      Home
                    </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <a className="scrollto" href="#about">
                      About us
                    </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <a className="scrollto" href="#services">
                      Services
                    </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <a className="scrollto" href="#faq">
                      FAQS
                    </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <a className="scrollto" href="#contact">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <Link to="/career" className="scrollto">
                      Career
                    </Link>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <Link to="/terms" className="scrollto">
                      Terms And Condiction
                    </Link>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>
                    <Link to="/privacy-policy" className="scrollto">
                      Privacy Policy
                    </Link>
                  </li>
                  {/* <li>
                    <i className="bi bi-chevron-right"></i>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  {services &&
                    services.map((item) => {
                      return (
                        <li key={item._id}>
                          <i className="bi bi-chevron-right"></i>
                          <Link to={`/serviceDetails/${item._id}`}>
                            {item.serviceName}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  Plot no. 961/2415, <br />
                  New Forest Park, Lane 2, 9,
                  <br />
                  Bhubaneswar, Odisha 751020
                  <br />
                  <br />
                  <strong>Phone:</strong> +91 9439581229
                  <br />
                  <strong>Email:</strong> hello@digitalrath.info
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright
            <strong>
              <span>Digitalrath</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
