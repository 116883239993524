import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CareerForm from "./CareerForm";
export default function Career() {
  useEffect(()=>{
    window.scrollTo(0, 0);
    document.title ='Career'
  },[])
  return (
    <>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Career</li>
            </ol>
            <h2>Career</h2>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up" data-aos-delay="200">
            <div className="row gy-4">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <CareerForm />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
