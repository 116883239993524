import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../formcontrols/InputField";
import * as Yup from "yup";
import axios from "axios";
import SelectInput from "../formcontrols/SelectInput";
import useAxiosGet from "./../hooks/useAxiosGet";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


export default function CareerForm() {
  const getExperienceLevelApi =
    "https://digital-rath-api-express.vercel.app/api/1.0/experiences";
  const getVacancyApi =
    "https://digital-rath-api-express.vercel.app/api/1.0/vacancies";

  let allExpereicne = useAxiosGet(getExperienceLevelApi);
  let allVacancies = useAxiosGet(getVacancyApi);
  const [showMessage, setShowMessage] = useState(false);
  const[loading, setLoading] = useState(false);
  const override = css`
  display: block;
  margin: 0 auto;
`;
  const AddCandidateApi =
    "https://digital-rath-api-express-4gf6lyu9b-digitalratha.vercel.app/api/1.0/addCandidate";
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const postCandidate = (payload, reset) => {
    setLoading(prev => !prev )
    axios
      .post(AddCandidateApi, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        console.log(response.data);
        setShowMessage(true);
        reset();
        setLoading(prev => !prev )
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const validate = Yup.object().shape({
    name: Yup.string()
      .max(50, "Name cannot be greater than 50 characters.")
      .required("Name cannot be blank"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required."),
    contactno: Yup.string()
      .matches(phoneRegExp, "Contact no is not valid")
      .required("Contact no is required."),
    appliedFor: Yup.string().required("Please select your intrested position."),
    experience: Yup.string().required("Please select your experience level."),
    resume: Yup.mixed().required('Please choose your resume')
  });
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          contactno: "",
          appliedFor: "",
          experience: "",
          resume: undefined,
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) =>
          postCandidate(values, resetForm)
        

        }
      >
        {(formik) => (

          <div>
            <Form className="php-email-form">
              <div className="row gy-4">
                <div className="col-md-4">
                  <InputField label="Your Name" name="name" type="text" />
                </div>
                <div className="col-md-4">
                  <InputField label="Your Email" name="email" type="email" />
                </div>
                <div className="col-md-4">
                  <InputField
                    label="Your Contact No (without +91)"
                    name="contactno"
                    type="text"
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput label="Intrested For" name="appliedFor" data={allVacancies} />
                </div>
                <div className="col-md-6">
                  <SelectInput label="Select Experience" name="experience" data={allExpereicne} />
                </div>
                <div className="col-md-12">
                  
                  <InputField
                    label="Select Your Resume"
                    name="resume"
                    type="file"
                    value={undefined}
                    onChange={(event) =>
                      formik.setFieldValue('resume', event.currentTarget.files[0])
                      
                    }
                    accept=".doc, .docx,.txt,.pdf,.rtf"
                  />
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit">Send Message</button>
                </div>
                {showMessage ? <Success /> : ""}
              </div>
            </Form>
          </div>
        )}
      </Formik>
      <ClipLoader color="#012970" loading={loading} css={override} size={50} />
    </>
  );

  function Success() {
    return (
      <>
        <div className="alert alert-success">Your query has been sent.</div>
      </>
    );
  }
}
