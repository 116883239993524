import React from "react";

export default function FAQ() {
  return (
    <>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up" data-aos-delay="200">
          <header className="section-header">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>

          <div className="row">
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="faqlist1">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      What is Digital Marketing?
                    </button>
                  </h2>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      Digital marketing is the process of promoting your brand
                      online to achieve business goals using multiple digital
                      channels such as email, social media, blogging, mobile,
                      web etc.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      How Does Digital Marketing Work?
                    </button>
                  </h2>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      Digital marketing uses data-driven strategies such as
                      email marketing, paid campaigns, on-page SEO, reputation
                      management etc. to establish brand value and brand
                      recognition among target customers
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      Why Choose Digital Marketing Over Traditional Marketing?
                    </button>
                  </h2>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      <ol>
                        <li>High level of customer engagement</li>
                        <li>Ease of measuring results</li>
                        <li>Cost-efficient and high ROI</li>
                        <li>Wider audience range</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="faqlist2">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2-content-1"
                    >
                      How Digital Marketing Helps to Grow Your Business?
                    </button>
                  </h2>
                  <div
                    id="faq2-content-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                  >
                    <div className="accordion-body">
                      <ol>
                        <li>Build brand awareness online</li>
                        <li>Generate revenue with targeted marketing</li>
                        <li>Generate quality leads</li>
                        <li>
                          Connect to your customers at a personal level on
                          social media
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2-content-2"
                    >
                      Why Should You Hire Digital Ratha for Digital Marketing?
                    </button>
                  </h2>
                  <div
                    id="faq2-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                  >
                    <div className="accordion-body">
                      We devise data-driven strategies for brands across all
                      niches. Work with one of the best digital marketing teams
                      in the industry. Digital Ratha has a track record of 94%
                      retention rate for its full-spectrum services.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
