import React, { useEffect } from "react";
import About from "./About";
import Contactus from "./Contactus";
import FAQ from "./FAQ";
import Service from "./Service";
import Values from "./Values";

export default function Home() {
  useEffect(()=>{
    document.title ='Digital Rath';
  },[])
  return (
    <>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">
              Turnkey Digital Transformation Services for Businesses Across All Industries
              </h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
              We are a team of Full-Stack Developers Passionate About all things Digital
              </h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <About />
        <Values />
        <Service />
    
        <FAQ />
        <Contactus />
      </main>
    </>
  );
}
