import React from 'react'

export default function Values() {
  return (
    <>
      <section id="values" className="values">
        <div className="container" data-aos="fade-up" data-aos-delay="200">
          <header className="section-header">
            <h2>Our Core Values</h2>
            <p>Innovate and Evolve With The World!</p>
          </header>

          <div className="row">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="box">
                <img src="assets/img/values-1.png" className="img-fluid" alt="" />
                <h3>Simplicity</h3>
                <p>
                  We take inspiration from everything but keep it simple when it comes to process, development and design.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="box">
                <img src="assets/img/values-2.png" className="img-fluid" alt="" />
                <h3>Accountability</h3>
                <p>
                  We love to take charge of the project to ensure we meet all requirements of our clients leaving no stone unturned.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="box">
                <img src="assets/img/values-3.png" className="img-fluid" alt="" />
                <h3>Value-Centric</h3>
                <p>
                  We make sure our work: design, content, and efforts return more values to our clients’ investment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )


}
