import React from "react";
import { Link } from "react-router-dom";
export default function About() {
  return (
    <>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gx-0">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="content">
                <h2>About Us</h2>
                <h3>
                  Digitalrath acts as a tech partner with a vision for the
                  business. We help you transform your concepts into effective
                  digital products by building them from scratch and providing
                  support for your project for as long as you need.
                </h3>
                {/* <p>
                  Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt
                  et. Inventore et et dolor consequatur itaque ut voluptate sed
                  et. Magnam nam ipsum tenetur suscipit voluptatum nam et est
                  corrupti.
                </p> */}
                <div className="text-center text-lg-start">
                  <Link
                    to="/aboutus"
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src="assets/img/about.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
