import React from "react";
import ContactusForm from "./ContactusForm";

export default function Contactus() {

  return (
    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up" data-aos-delay="200">
          <header className="section-header">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </header>

          <div className="row gy-4">
            <div className="col-lg-5">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      Plot no. 961/2415, New Forest Park, Lane 2, 9,
                      <br />
                      Bhubaneswar, Odisha 751020
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>
                      +91 9439581229

                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      hello@digitalrath.info
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>
                      Monday to Saturdays
                      <br />
                      9 am to 8 pm
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <ContactusForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
