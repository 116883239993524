import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../formcontrols/InputField";
import TextArea from "../formcontrols/TextArea";
import * as Yup from "yup";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

export default function ContactusForm() {
  const [showMessage, setShowMessage] = useState(false);
  const[loading, setLoading] = useState(false);
  const AddContactApi =
    "https://digital-rath-api-express.vercel.app/api/1.0/addContact";

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const postContact = (payload, reset) => {
    setLoading(prev => !prev )
    axios
      .post(AddContactApi, payload)
      .then(function (response) {
        console.log(response.data);
        setShowMessage(true)
        reset();
        setLoading(prev => !prev )
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const validate = Yup.object({
    name: Yup.string()
      .max(50, "Name cannot be greater than 50 characters.")
      .required("Name cannot be blank"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required."),
    contactno: Yup.string()
      .matches(phoneRegExp, "Contact no is not valid")
      .required("Contact no is required."),
    message: Yup.string()
      .required("Message cannot be blank")
      .max(255, "Message cannot be greater than 255 characters."),
  });
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          contactno: "",
          email: "",
          message: "",
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => postContact(values, resetForm)}
      >
        {(formik) => (
          <div>
            <Form className="php-email-form">
              <div className="row gy-4">
                <div className="col-md-4">
                  <InputField label="Your Name" name="name" type="text" />
                </div>
                
                <div className="col-md-4">
                  <InputField
                    label="Your Contact No"
                    name="contactno"
                    type="text"
                  />
                </div>
                <div className="col-md-4">
                  <InputField label="Your Email" name="email" type="email" />
                </div>
                <div className="col-md-12">
                  <TextArea label="Your Message" name="message" />
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit">Send Message</button>
                </div>
                {showMessage ? <Success /> : ""}
              </div>
            </Form>
          </div>
        )}
      </Formik>
      <ClipLoader color="#012970" loading={loading} css={override} size={50} />
    </>
  );

  function Success() {
    return (
      <>
        <div className="alert alert-success">Your query has been sent.</div>
      </>
    );
  }
}
