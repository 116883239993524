import { ErrorMessage, useField } from "formik";
import React from "react";

export default function InputField({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <input className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`} autoComplete="off" placeholder={label}
      {...field} {...props}
      />
      <ErrorMessage component='div' className="errormsg"  name={field.name}/>
    </>
  );
}
