import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  let isHome = false;
  location.pathname === "/" ? (isHome = true) : (isHome = false);
  return (
    <header id="header" className="header fixed-top header-scrolled">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt="" className="d-lg-block d-none" />
          <img src="assets/img/logo.png" alt="" style={{width:'150px'}} className="d-lg-none d-md-none" />
          {/* <span>DigitalRath</span> */}
        </Link>
        {isHome ? <NavHome /> : <></>}
      </div>
    </header>
  );

  function NavHome() {
    return (
      <>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <Link className="nav-link scrollto active" to="/">
                Home
              </Link>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#services">
                Services
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#faq">
                Faqs
              </a>
            </li>
            {/* <li><a className="nav-link scrollto" href="#portfolio">Portfolio</a></li>
    <li><a className="nav-link scrollto" href="#team">Team</a></li>
    <li><a href="blog.html">Blog</a></li> */}
            {/* <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
      <ul>
        <li><a href="#">Drop Down 1</a></li>
        <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
          <ul>
            <li><a href="#">Deep Drop Down 1</a></li>
            <li><a href="#">Deep Drop Down 2</a></li>
            <li><a href="#">Deep Drop Down 3</a></li>
            <li><a href="#">Deep Drop Down 4</a></li>
            <li><a href="#">Deep Drop Down 5</a></li>
          </ul>
        </li>
        <li><a href="#">Drop Down 2</a></li>
        <li><a href="#">Drop Down 3</a></li>
        <li><a href="#">Drop Down 4</a></li>
      </ul>
    </li> */}
            <li>
              <a className="nav-link scrollto" href="#contact">
                Contact
              </a>
            </li>
            <li>
              <Link to="/career" className="nav-link scrollto">
                Career
              </Link>
            </li>
            {/* <li><a className="getstarted scrollto" href="#about">Get Started</a></li> */}
          </ul>
          {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
          <Link to="/career" className="nav-link scrollto d-md-none d-lg-none">
               <small>Career </small>
          </Link>
        </nav>
      </>
    );
  }
}
